import { Row,Col,Card } from 'react-bootstrap'
import {ReactComponent as Emblem} from '../images/emblem.svg';

import {ReactComponent as Onsite} from '../images/onsite.svg';
import {ReactComponent as Online} from '../images/online.svg';
import {ReactComponent as NotWatching} from '../images/notwatching.svg';
import {ReactComponent as Forget} from '../images/forget.svg';

const GameInfo = ({game,postType}) => {
  // console.log(match)
  const headerStyle = (game) => {
    let backgroundColor;
    let textColor;
    if (game.is_neutral) {
      backgroundColor = '#f8f9fa';
      textColor = 'black';
    } else if (game.is_home) {
      backgroundColor = game.home_color;
      textColor = game.home_black ? 'black' : 'white';
    } else {
      backgroundColor = '#6c757d';
      textColor = 'white';
    }
    return {backgroundColor: backgroundColor, color: textColor}
  }

  const postTypeIcon = (postType) => {
    if (postType === 1) {
      return (
        <>
          <Onsite fill="#696969" style={{width: "40",height: "40"}}/>
          <span className="d-block" style={{fontSize: "0.8rem"}}>現地観戦</span>
        </>
      )
    } else if (postType === 2) {
      return (
        <>
          <Online fill="#696969" style={{width: "40",height: "40"}}/>
          <span className="d-block" style={{fontSize: "0.7rem"}}>オンライン</span>
        </>
      )
    } else if (postType === 3) {
      return (
        <>
          <NotWatching fill="#696969" style={{width: "40",height: "40"}}/>
          <span className="d-block" style={{fontSize: "0.7rem"}}>観ていない</span>
        </>
      )
    } else if (postType === 4) {
      return (
        <>
          <Forget fill="#696969" style={{width: "40",height: "40"}}/>
          <span className="d-block" style={{fontSize: "0.8rem"}}>忘れた</span>
        </>
      )
    }
  }
  
  const home_or_away_or_neutral = (is_neutral,is_home) => {
    return is_neutral ? "Neutral" : (is_home ? "Home" : "Away")
  }

  return (
    <>
      <Card.Header className="text-center" style={headerStyle(game)}>
        <Row>
          <Col xs={3} className="d-flex justify-content-center align-items-center">{home_or_away_or_neutral(game.is_neutral,game.is_home)}</Col>
          <Col xs={6} className="d-flex justify-content-center align-items-center small">{game.date_time}</Col>
          <Col xs={3} className="d-flex justify-content-center align-items-center small">{game.title}</Col>
        </Row>
      </Card.Header>
      <Card.Body className="text-center">
        <Card.Title>
          <Row className="justify-content-center">
            <Col className="align-items-end">
              <Emblem className="me-1" height="25" width="25" fill={`${game.is_home ? game.away_color : game.home_color}`} style={{verticalAlign: "middle"}} stroke="gray" strokeWidth="10"/>
              <span style={{verticalAlign: "middle"}}>{game.is_home ? game.away_name : game.home_name}</span>
              <span className="ms-1" style={{verticalAlign: "middle"}}>戦</span>
            </Col>
          </Row>
        </Card.Title>
      <Row>
        <Col xs={3} >{postType && postTypeIcon(postType)}</Col>
        <Col xs={6} className="h1">
          <Row>
            <Col xs={5} className="d-flex justify-content-end align-items-center" style={{fontSize: game.is_home ? "3rem": "1.8rem", verticalAlign: "middle"}}>{String(game.home_goal_players.length)}</Col>
            <Col xs={2} className="d-flex justify-content-center align-items-center" style={{verticalAlign: "middle"}}>-</Col>
            <Col xs={5} className="d-flex justify-content-start align-items-center" style={{fontSize: game.is_home ? "1.8rem" : "3rem", verticalAlign: "middle"}}>{String(game.away_goal_players.length)}</Col>
          </Row>
        </Col>
        <Col xs={3}/>
      </Row>
      {game && (game.home_goal_players) && (game.home_goal_players.length > 0 || game.away_goal_players.length > 0) && (
        <>
          <div className="mx-5">
            <hr />
          </div>
          <div className="text-center h5 text-secondary">
            得点者
          </div>
          <Row className="text-secondary" style={{fontSize: "0.75rem"}}>
            <Col className="text-start px-3" style={{height: "70px", overflow:"auto"}}>
              {game.home_goal_players && game.home_goal_players.map((player, index) => (
                <div key={index}>
                  <span>{player.name} ({player.time}')</span>
                </div>
              ))}
            </Col>
            <Col className="text-start ps-1" style={{height: "70px", overflow:"auto"}}>
              {game.away_goal_players && game.away_goal_players.map((player, index) => (
                <div key={index}>
                  <span>{player.name} ({player.time}')</span>
                </div>
              ))}
            </Col>
          </Row>
        </>
      )}
      {game && (game.home_red_players) && (game.home_red_players.length > 0 || game.away_red_players.length > 0) && (
        <>
          <div className="mx-5">
            <hr />
          </div>
          <div className="text-center h5 text-secondary mt-3">
            退場者
          </div>
          <Row className="text-secondary" style={{fontSize: "0.75rem"}}>
            <Col className="text-start px-3" style={{height: "30px", overflow:"auto"}}>
              {game.home_red_players && game.home_red_players.map((player, index) => (
                <div key={index}>
                  <span>{player.name} ({player.time}')</span>
                </div>
              ))}
            </Col>
            <Col className="text-start pe-1" style={{height: "30px", overflow:"auto"}}>
              {game.away_red_players && game.away_red_players.map((player, index) => (
                <div key={index}>
                  <span>{player.name} ({player.time}')</span>
                </div>
              ))}
            </Col>
          </Row>
        </>
      )}
      <Row className="text-end">
        <Col className="mx-5">
          <hr />
        </Col>
        <Col xs={12}>
          <span className="text-muted small">＠ {game.stadium_name}</span>
        </Col>
        <Col xs={12}>
          <span className="text-muted small">観客数：{game.mobilization}人</span>
        </Col>
      </Row>
      </Card.Body>
    </>
  )
}

export default GameInfo;