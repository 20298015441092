import React from 'react'

import {Row,Col,Card} from 'react-bootstrap'
import PostButton from './PostButton';
import GameInfo from './GameInfo'

const GameCard = ({game,onClickPost}) => {
  return (
    <div id={`${game.data.id}`} style={{height: `${game.style.height}px`,overflow:'hidden'}}>
      <Card>
        <GameInfo game={game.data}/>
        <Card.Footer>
          <Row className="text-center">
            <Col><PostButton isHome={game.data.is_home} gameId={game.data.id} msg="現地観戦" postType={1} onClickPost={onClickPost}/></Col>
            <Col><PostButton isHome={game.data.is_home} gameId={game.data.id} msg="オンライン" postType={2} onClickPost={onClickPost}/></Col>
            <Col><PostButton isHome={game.data.is_home} gameId={game.data.id} msg="観ていない" postType={3} onClickPost={onClickPost}/></Col>
            <Col><PostButton isHome={game.data.is_home} gameId={game.data.id} msg="忘れた" postType={4} onClickPost={onClickPost}/></Col>
          </Row>
        </Card.Footer>
      </Card>
    </div>
  )
}

export default GameCard;