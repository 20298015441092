import {useState,useEffect} from 'react';
import {Card} from 'react-bootstrap'
import GameInfo from './GameInfo';
import axios from 'axios'

const PostsClubs = ({clubId}) => {

  const [posts, setPosts] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/posts/clubs/${clubId}`,
    {
      headers: {
        uid: localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client')
      },
      params: {
        user_id: JSON.parse(localStorage.currentUser).id,
      }
    })
    .then(response => response.data)
    .then(data => {
      setPosts(data);
    })
  },[])

  return (
    <>
      {
        posts.map((post) => {
          return (
            <div key={post.post_id} className="my-1 mx-1">
              <Card>
                <GameInfo game={post}/>
              </Card>
            </div>
          )
        })
      }
    </>
  )
}

export default PostsClubs;