import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {Container, Form, Button, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';


const AddTitle = () => {

  const [titles,setTitles] = useState({});
  const [name,setName] = useState('');
  const [currentTitleId,setCurrentTitleId] = useState(0);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [submitButtonLabel, setSubmitButtonLabel] = useState('タイトル作成');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/admin/titles/`,
      {
        headers: {
          uid: localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client')
        },
        params: {
          index_type: 'index',
        }
      }
    ).then((response) => {
      if(response.status === 200){
        setTitles(response.data);
      } else {
        setErrorMessage('サーバーエラーが発生しました。');
      }
    }).catch((error) => {
      if(error.response && error.response.status === 401){
        history.push('/sign-in');
      } else {
        setErrorMessage('サーバーエラーが発生しました。');
      }
    })
  },[history])

  const handleNameChange = (e) => {
    setName(e && e.target ? e.target.value : '');
    setIsSubmitDisable(!name)
  }
  
  const handleCurrentTitleIdChange = (e) => {
    setCurrentTitleId(e && e.target ? e.target.value : 0);
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
  }

  const createTitle = () => {
    setIsSubmitDisable(true);
    setSubmitButtonLabel('タイトル作成中...');
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/admin/titles`,
      {
        name: name,
        current_title_id: currentTitleId,
      },
      {
        headers: {
          uid: localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client')
        }
      },
    ).then((response) => {
      if(response.status !== 200){
        setErrorMessage('サーバーエラーが発生しました。');
        setIsSubmitDisable(false);
        setSubmitButtonLabel('タイトル作成');
      }
    }).catch((error) => {
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/sign-in');
      } else {
        setErrorMessage('サーバーエラーが発生しました。');
        setIsSubmitDisable(false);
        setSubmitButtonLabel('タイトル作成');
      }
    })
  }

  return (
    <Layout>
      <Container>
        {errorMessage ? <div className="my-3 text-danger">{errorMessage}</div> : <div></div>}
        <Form onSubmit={handleSubmit} className="my-3">
          <Form.Group className="mb-3" controlId="formTitleName">
            <Form.Label>タイトル名</Form.Label>
            <Form.Control value={name} placeholder="タイトル名を入力してください" onChange={handleNameChange}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formCurrentTitleId">
            <Form.Label>current_title_id(新規作成の場合は0にしてください)</Form.Label>
            <Form.Control value={currentTitleId} placeholder="紐付けるtitleのidを入力してください" onChange={handleCurrentTitleIdChange}/>
          </Form.Group>
          <Form.Group className="mb-3 text-end">
            <Button variant="dark" type="submit" onClick={createTitle} disabled={isSubmitDisable}>
              {submitButtonLabel}
            </Button>
          </Form.Group>
        </Form>
        <Row>
          <Col>id</Col>
          <Col>name</Col>
          <Col>is_valid</Col>
          <Col>current_title_id</Col>
        </Row>
        {
          Object.entries(titles).map(title => {
            return (
              <Row>
                <Col>{title[1].id}</Col>
                <Col>{title[1].name}</Col>
                <Col>{title[1].is_valid ? "true" : "false"}</Col>
                <Col>{title[1].current_title_id}</Col>
              </Row>
            )
          })
        }
      </Container>
    </Layout>
  )
}
export default AddTitle;