import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';

const AddStadium = () => {

  const [stadia,setStadia] = useState({});
  const [name,setName] = useState('');
  const [currentStadiumId,setCurrentStadiumId] = useState(0);
  const [prefCode,setPrefCode] = useState(0);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [submitButtonLabel, setSubmitButtonLabel] = useState('スタジアム作成');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/admin/stadia/`,
      {
        headers: {
          uid: localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client')
        },
        params: {
          index_type: 'index',
        }
      }
    ).then((response) => {
      if(response.status === 200){
        setStadia(response.data);
      } else {
        setErrorMessage('サーバーエラーが発生しました。');
      }
    }).catch((error) => {
      if(error.response && error.response.status === 401){
        history.push('/sign-in');
      } else {
        setErrorMessage('サーバーエラーが発生しました。');
      }
    })
  },[history])

  const handleNameChange = (e) => {
    setName(e && e.target ? e.target.value : '');
    setIsSubmitDisable(!((prefCode && name) || (prefCode && name)));
  }

  const handlePrefCodeChange = (e) => {
    setPrefCode(e && e.target ? e.target.value : 0);
    setIsSubmitDisable(!((prefCode && name) || (prefCode && name)));
  }

  const handleCurrentStadiumIdChange = (e) => {
    setCurrentStadiumId(e && e.target ? e.target.value : 0);
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
  }

  const createStadium = () => {
    setIsSubmitDisable(true);
    setSubmitButtonLabel('スタジアム作成中...');
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/admin/stadia`,
      {
        name: name,
        current_stadium_id: currentStadiumId,
        pref_code: prefCode,
      },
      {
        headers: {
          uid: localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client')
        }
      },
    ).then((response) => {
      if(response.status === 200){
        setIsSubmitDisable(false);
        setSubmitButtonLabel('スタジアム作成');
      }
    }).catch((error) => {
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/sign-in');
      } else {
        setErrorMessage('サーバーエラーが発生しました。');
        setIsSubmitDisable(false);
        setSubmitButtonLabel('スタジアム作成');
      }
    })
  }

  return (
    <Layout>
      <Container>
      {errorMessage ? <div className="my-3 text-danger">{errorMessage}</div> : <div></div>}
  
        <Form onSubmit={handleSubmit} className="my-3">
          <Form.Group className="mb-3" controlId="formStadiumName">
            <Form.Label>スタジアム名</Form.Label>
            <Form.Control value={name} placeholder="スタジアム名を入力してください" onChange={handleNameChange}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPrefCode">
            <Form.Label>PrefCode</Form.Label>
            <Form.Control value={prefCode} placeholder="prefCodeを入力してください" onChange={handlePrefCodeChange} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formCurrentStadiumId">
            <Form.Label>cuttrnt_stadium_id(新規作成の場合は0にしてください)</Form.Label>
            <Form.Control value={currentStadiumId} placeholder="紐付けるstadiumのidを入力してください" onChange={handleCurrentStadiumIdChange} />
          </Form.Group>
          <Form.Group className="mb-3 text-end">
            <Button variant="dark" type="submit" onClick={createStadium} disabled={isSubmitDisable}>
              {submitButtonLabel}
            </Button>
          </Form.Group>
        </Form>
        <Row>
          <Col xs={1}>id</Col>
          <Col xs={5}>name</Col>
          <Col xs={2}>pref_code</Col>
          <Col xs={2}>is_valid</Col>
          <Col xs={2}>current_stadium_id</Col>
        </Row>
        {
          Object.entries(stadia).map(stadium => {
            return (
              <Row>
                <Col xs={1}>{stadium[1].id}</Col>
                <Col xs={5}>{stadium[1].name}</Col>
                <Col xs={2}>{stadium[1].pref_code}</Col>
                <Col xs={2}>{stadium[1].is_valid ? "true" : "false"}</Col>
                <Col xs={2}>{stadium[1].current_stadium_id}</Col>
              </Row>
            )
          })
        }
      </Container>
    </Layout>
  )
}
export default AddStadium;