import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../components/Layout';
import { Container, Row, Col} from 'react-bootstrap'

const UserIndex = () => {

  const [users,setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/admin/users`,
      {
        headers: {
          uid: localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client')
        }
      }
    ).then((response) => {
      if(response.status === 200){
        setUsers(response.data);
      } else {
        setErrorMessage('サーバーエラーが発生しました。');
      }
    })
  },[])

  return (
    <Layout>
      <Container>
        <Row>
          <Col xs={1}>id</Col>
          <Col xs={2}>name</Col>
          <Col xs={2}>team</Col>
          <Col xs={1}>onsite</Col>
          <Col xs={1}>total</Col>
          <Col xs={3}>created_at</Col>
        </Row>
        {errorMessage ? <div className="my-3 text-danger">{errorMessage}</div> : <div></div>}
        {
            Object.entries(users).map(user => {
              return (
                <Row>
                  <Col xs={1}>{JSON.stringify(user[0])}</Col>
                  <Col xs={2}>{JSON.stringify(user[1].name)}</Col>
                  <Col xs={2}>{JSON.stringify(user[1].team_name)}</Col>
                  <Col xs={1}>{JSON.stringify(user[1].onsite_post_size)}</Col>
                  <Col xs={1}>{JSON.stringify(user[1].total_post_size)}</Col>
                  <Col xs={3}>{JSON.stringify(user[1].created_at)}</Col>
                </Row>
              )
            })
          }
      </Container>
    </Layout>
  )
}
export default UserIndex;