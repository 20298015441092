import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../components/Head';
import Layout from '../components/Layout';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller'
import { Container, Spinner } from 'react-bootstrap';
import MatchCard from '../components/MatchCard';
import GameCard from '../components/GameCard';
import { TransitionMotion, spring } from 'react-motion';
import ReturnTopButton from '../components/ReturnTopButton';

const Matches = () => {

  const [games, setGames] = useState([]);
  const [gameIds, setGameIds] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetcing] = useState(false);

  const loadMore = async () => {
    setIsFetcing(true);
    try {
      if(gameIds.length !== 0){
        const data = await getGames();
        if (data.length < 10) {
          setHasMore(false);
          return;
        }
        if(games.length !== 0){
          setGames([...games,...data]);
        } else {
          setGames(data);
        }
      }
    } finally {
      setIsFetcing(false);
    }
  }

  const loader = <Spinner key={0} animation="border" variant="secondary" />

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/game_ids`, {
      headers: {
        uid: localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client')
      },
    }).then((response) => {
      return response.data;
    }).then((data) => {
      setGameIds(data);
    })
  },[]);

  const getGames = async () => {
    const response = await axios(`${process.env.REACT_APP_API_ENDPOINT}/games?game_ids=${gameIds[page].join(',')}`,
      {
        headers: {
          uid: localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client')
        },
        params: {
          index_type: 'ordinal'
        }
      }
    );
    setPage(() => page+1);

    return response.data
  }

  const willLeave = () => { 
    return {height: spring(0, {stiffness:240, dumping:30})}
  }

  const onClickPost = (gameId) => {
    setGames(games.filter((game) => game.id !== gameId))
  }

  const cardHeight = (game) => {
    let h = 350;
    if (game.home_goal_players.length !== 0 || game.away_goal_players.length !== 0) {
      h += 140
    }
    if (game.home_red_players.length !== 0 || game.away_red_players.length !== 0) {
      h += 100
    }
    return h
  }

  return (
    <HelmetProvider>
      <Layout>
        <Head title="観戦記録の作成" />
        <Container>
          <div className="mt-4">
            <InfiniteScroll loadMore={loadMore} hasMore={!isFetching && hasMore} loader={loader} className="text-center">
              <TransitionMotion
                willLeave={willLeave}
                styles={
                  games.map((game) => (
                    {
                      key: String(game.id),
                      data: game,
                      style: {height: cardHeight(game)}
                    }
                  ))
                }
              >
                {interpolatingStyles =>
                  <>
                    {interpolatingStyles.map((interpolatingStyle) => {
                      return (
                        <GameCard key={interpolatingStyle.key} game={interpolatingStyle} onClickPost={onClickPost} />
                      );
                    })}
                  </>
                }
              </TransitionMotion>
            </InfiniteScroll>
          </div>
        </Container>
      </Layout>
      <ReturnTopButton />
    </HelmetProvider>
  )
}

export default Matches;