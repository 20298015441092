import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Carousel, Row, Col} from 'react-bootstrap'
import axios from 'axios'
const MatchResults = ({userId}) => {
  const [matchResults,setMatchResults] = useState([])
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  }
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/posts/summaries/show` ,{
      headers: {
        uid: localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client')
      }
    })
    .then(response => response.data)
    .then(data => {
      setMatchResults(data);
    })
  },[userId])

  const winRate = (win,lose,draw) => {
    if (win + lose + draw === 0) {
      return '-'
    } else {
      return Math.round(parseFloat(win)/(win + lose + draw) * 100000) / 1000;
    }
  }

  return (
    <>
      {matchResults.total && (matchResults.total.win + matchResults.total.lose + matchResults.total.draw === 0 ? 
        <div className="my-4 text-center bg-light rounded border py-3">
          まだ現地観戦記録がありません。<br />
          下のボタンから観戦記録を作成しましょう！<br />
          <Link to="/matches" className="btn btn-secondary mt-3">観戦記録を作る</Link>
        </div>
        :
        <>
          <h3 className="h5">現地観戦数</h3>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            slide={true}
            interval={null}
            indicators={false}
            >
              <Carousel.Item key={"total"}>
                <Row className="align-items-end ms-1">
                  <Col xs={12} className="text-center" style={{paddingRight: "9rem",marginBottom: "-2rem"}}>
                    <span style={{fontSize: "1.3rem"}}>通算</span>
                  </Col>
                  <Col xs={7} className="text-end">
                    <div>
                      <span style={{fontSize: "5rem",Color: "tomato"}} >{matchResults["total"].win + matchResults["total"].lose + matchResults["total"].draw}</span><span>戦</span>
                    </div>
                    <div style={{marginTop: "-1rem"}}>勝率：<span style={{fontSize: "2rem"}}>{winRate(matchResults["total"].win, matchResults["total"].lose, matchResults["total"].draw)}</span><span style={{fontSize: "0.75rem"}}>%</span></div>
                  </Col>
                  <Col xs={4} className="text-start" style={{marginLeft: "1.25rem"}}>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["total"].win}</span>勝</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["total"].lose}</span>敗</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["total"].draw}</span>分</div>
                  </Col>
                </Row>
              </ Carousel.Item>
              <Carousel.Item key={"home"}>
                <Row className="align-items-end ms-1">
                  <Col xs={12} className="text-center" style={{paddingRight: "9rem",marginBottom: "-2rem"}}>
                    <span style={{fontSize: "1.3rem"}}>ホーム通算</span>
                  </Col>
                  <Col xs={7} className="text-end">
                    <div>
                      <span style={{fontSize: "5rem",Color: "tomato"}} >{matchResults["home"].win + matchResults["home"].lose + matchResults["home"].draw}</span><span>戦</span>
                    </div>
                    <div style={{marginTop: "-1rem"}}>勝率：<span style={{fontSize: "2rem"}}>{winRate(matchResults["home"].win, matchResults["home"].lose, matchResults["home"].draw)}</span><span style={{fontSize: "0.75rem"}}>%</span></div>
                  </Col>
                  <Col xs={4} className="text-start" style={{marginLeft: "1.25rem"}}>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["home"].win}</span>勝</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["home"].lose}</span>敗</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["home"].draw}</span>分</div>
                  </Col>
                </Row>
              </ Carousel.Item>
              <Carousel.Item key={"away"}>
                <Row className="align-items-end ms-1">
                  <Col xs={12} className="text-center" style={{paddingRight: "9rem",marginBottom: "-2rem"}}>
                    <span style={{fontSize: "1.3rem"}}>アウェイ通算</span>
                  </Col>
                  <Col xs={7} className="text-end">
                    <div>
                      <span style={{fontSize: "5rem",Color: "tomato"}} >{matchResults["away"].win + matchResults["away"].lose + matchResults["away"].draw}</span><span>戦</span>
                    </div>
                    <div style={{marginTop: "-1rem"}}>勝率：<span style={{fontSize: "2rem"}}>{winRate(matchResults["away"].win, matchResults["away"].lose, matchResults["away"].draw)}</span><span style={{fontSize: "0.75rem"}}>%</span></div>
                  </Col>
                  <Col xs={4} className="text-start" style={{marginLeft: "1.25rem"}}>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["away"].win}</span>勝</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["away"].lose}</span>敗</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["away"].draw}</span>分</div>
                  </Col>
                </Row>
              </ Carousel.Item>
              <Carousel.Item key={"neutral"}>
                <Row className="align-items-end ms-1">
                  <Col xs={12} className="text-center" style={{paddingRight: "9rem",marginBottom: "-2rem"}}>
                    <span style={{fontSize: "1.3rem"}}>中立地通算</span>
                  </Col>
                  <Col xs={7} className="text-end">
                    <div>
                      <span style={{fontSize: "5rem",Color: "tomato"}} >{matchResults["neutral"].win + matchResults["neutral"].lose + matchResults["neutral"].draw}</span><span>戦</span>
                    </div>
                    <div style={{marginTop: "-1rem"}}>勝率：<span style={{fontSize: "2rem"}}>{winRate(matchResults["neutral"].win, matchResults["neutral"].lose, matchResults["neutral"].draw)}</span><span style={{fontSize: "0.75rem"}}>%</span></div>
                  </Col>
                  <Col xs={4} className="text-start" style={{marginLeft: "1.25rem"}}>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["neutral"].win}</span>勝</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["neutral"].lose}</span>敗</div>
                    <div className="pt-2"><span style={{fontSize: "2rem"}}>{matchResults["neutral"].draw}</span>分</div>
                  </Col>
                </Row>
              </ Carousel.Item>
          </Carousel>
        </>
      )}
    </>
  );
}

export default MatchResults;